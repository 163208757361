<template>
  <div class="message-wrapper">
    <AvatarBubble :text="initials" class="avatar" :image="message?.author?.media?.url" />
    <div class="message" :id="copyTrigger">
      <div>
        <div class="message-author">{{ userName }}</div>
        <div class="message-date">{{ messageDate }}</div>
      </div>
      <div v-html="messageText" class="message-text" />
      <div class="attachments">
        <div class="images" v-if="images.length > 0">
          <img v-for="image in images"
               :src="buildImageTinyUrl(image)"
               :key="image" alt=""
               class="image"
               @click="openInPopover(image)"/>
        </div>
        <div v-if="otherAttachments.length > 0" class="other-attachments">
          <a v-for="attachment in otherAttachments"
             :href="buildAttachmentUrl(attachment)"
             :key="attachment"
             class="attachment-link">{{ attachmentName(attachment) }}</a>
        </div>
        <ion-popover :is-open="isImagePopoverOpen" :dismiss-on-select="true" @didDismiss="resetPopover" class="image-popover">
          <img :src="popoverContent" alt="" class="modal-image" />
        </ion-popover>
      </div>
    </div>
    <ion-popover v-if="copyTrigger" :trigger="copyTrigger" :dismiss-on-select="true" trigger-action="click" class="copy-popover">
      <div class="copy-popover-inner" @click="copyMessageText"><Icon name="copy" class="copy-popover-inner-icon" />Copy text</div>
    </ion-popover>
  </div>
</template>

<script>
import { buildAttachmentUrl, buildImageTinyUrl, buildImageLargeUrl } from '@/helpers/attachment.js';
import { IonPopover } from '@ionic/vue';
import {hourNumericMinuteNumeric} from '@/helpers/date-format.js';
import AvatarBubble from '@officeguru/components-vue3/src/components/Avatar.vue';
import Icon from '@officeguru/components-vue3/src/components/Icon.vue';
import { avatarInitials } from '@officeguru/webapp-shared/src/helpers/avatar-helper.js';
import { Clipboard } from '@capacitor/clipboard';
import { randomString } from '@/helpers/random';
import striptags from 'striptags';

export default {
  name: 'ConversationMessageAreaMessageMessage',
  components: {
    AvatarBubble,
    IonPopover,
    Icon,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    owner: {
      type: Boolean,
    }
  },
  data() {
    return {
      isImagePopoverOpen: false,
      popoverContent: null,
      buildAttachmentUrl,
      buildImageTinyUrl,
      copyTriggerId: randomString(),
    }
  },
  computed: {
    copyTrigger() {
      return this.messageText ? this.copyTriggerId : null
    },
    messageDate() {
      return hourNumericMinuteNumeric(this.message.message.date);
    },
    messageText() {
      return this.message.message.text;
    },
    userName() {
      return this.message.author.name;
    },
    images() {
      return this.message.images || [];
    },
    otherAttachments() {
      return this.message.attachments || [];
    },
    initials() {
      return avatarInitials(this.userName);
    }
  },
  methods: {
    copyMessageText() {
      const onlyBreakingTags = striptags(typeof this.messageText === 'string' ? this.messageText : '', ['br', 'p'], ' ').trim();
      const breakingTagsAsNewlines = striptags(onlyBreakingTags, [], '\n').trim();
      const almostPlainMessage = breakingTagsAsNewlines.replace(/\n{2,}/g, '\n');
      const plainMessage = almostPlainMessage.replace(/&nbsp;/g, ' ');

      Clipboard.write({
        string: plainMessage,
      });
    },
    attachmentName(attachment) {
      return attachment.split('/')[1];
    },
    openInPopover(image) {
      this.popoverContent = buildImageLargeUrl(image);
      this.isImagePopoverOpen = true;
    },
    resetPopover() {
      this.popoverContent = null;
      this.isImagePopoverOpen = false;
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

.message-wrapper {
  padding: 8px 16px;
  display: flex;
}

.message {
  margin-top: -3px;
}

.avatar {
  flex-shrink: 0;
  margin-right: 12px;
  width: 32px;
  height: 32px;

  ::v-deep .text {
    height: 17px !important;
  }
}


.message-author {
  @include font-body-semi;
  color: $color-grey-900;
  display: inline-flex;
  margin-right: 8px;
}

.message-date {
  @include font-caption-reg;
  color: $color-grey-500;
  display: inline-flex;
}

.message-text {
  @include font-body-reg;
  color: $color-grey-900;
}

.message-text {
  :deep(p) {
    margin: 0;
    padding: 0;
  }
}

.attachments {
  display: flex;
  flex-direction: column;
}

.other-attachments {
  @include font-body-reg;
  color: $color-grey-900;
  min-width: 0;
  flex: 0 1 0;
}

.attachment-link {
  display: block;
  word-break: break-all;
}

.images {
  display: flex;
  //gap: 8px; not supported
  flex-wrap: wrap;
  margin-top: 8px;
}

.image {
  object-fit: cover;
  min-width: 15vw;
  max-width: 15vw;
  max-height: 15vw;
  min-height: 15vw;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.modal-image {
  max-height: 100vh;
  max-width: 100vw;
  margin: auto;
}

.image-popover {
  --ion-backdrop-opacity: 1;
  --ion-backdrop-color: black;
  --min-width: 100vw;
  --min-height: 100vh;
  --background: none;
  --box-shadow: none;
  margin-left: -12px;
}

.copy-popover {
  --ion-backdrop-opacity: 0;
  --box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  --width: auto;
  top: 4px;
}

.copy-popover-inner {
  @include font-body-reg;
  color: $color-grey-800;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  cursor: pointer;
}

.copy-popover-inner-icon {
  color: $color-grey-800;
  margin-right: 6px;
  margin-top: -2px;
}
</style>
