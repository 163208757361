<template>
  <div
    data-cy="cmp-search-bar"
    class="search-bar"
  >
    <icon
      name="search"
      class="search-bar__icon"
    />
    <input
      class="search-bar__input"
      :value="modelValue"
      :placeholder="placeholder"
      @keyup="searchDebounced"
      @paste="searchDebounced"
    >
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import Icon from './Icon.vue';

const SEARCH_DEBOUNCE_MS = 500;

export default {
  name: 'SearchBar',
  components: {
    Icon,
  },
  compatConfig: {
    MODE: 3,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  methods: {
    // eslint-disable-next-line func-names
    searchDebounced: debounce(function ($event) {
      if (this.modelValue === $event.target.value) return;
      this.$emit('update:modelValue', $event.target.value);
    }, SEARCH_DEBOUNCE_MS),
  },
};
</script>

<style scoped lang="scss">
@import "../style/main";

.search-bar {
  width: 100%;
  display: flex;
  border: 1px solid $color-grey-400;
  border-radius: 8px;
  padding: 8px 16px 5px 16px;
  background: white;

  &__input {
    @include font-body-reg;
    width: 100%;
    line-height: 0;
    color: $color-grey-800;
    margin-top: -2px;
    background: transparent;

    &::placeholder {
      color: $color-grey-400;
    }

    &:focus {
      outline: none;
    }
  }

  &__icon {
    color: $color-grey-600;
    margin-right: 8px;
  }
}
</style>
