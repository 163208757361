<template>
  <div>
    <div class="search-wrapper">
      <SearchBar
        class="search"
        :placeholder="`${$t('ogchat.bulk.search')}...`"
        :model-value="search"
        @update:model-value="$emit('search', $event)"
      />
    </div>
    <!-- Filter pages -->
    <div
      v-for="filter in filterPages"
      :key="filter.key"
      class="filter-option"
      @click="$emit('show-filter', filter.key)"
    >
      <div class="filter-option-description">{{ filter.description }}</div>
      <div class="filter-option-action">
        <span class="filter-option-action-label">{{ filter.label }}</span>
        <Icon name="arrow-right" class="filter-option-action-icon" :size="22" />
      </div>
    </div>
  </div>
</template>
<script>
import Icon from "@officeguru/components-vue3/src/components/Icon.vue";
import { SearchBar } from "@officeguru/components-vue3";

export default {
  components: {
    Icon,
    SearchBar,
  },
  props: {
    search: {
      type: String,
      default: "",
    },
    serviceLabel: {
      type: String,
      default: "",
    },
    contractStatusLabel: {
      type: String,
      default: "",
    },
  },
  computed: {
    filterPages() {
      return [
        {
          label: this.serviceLabel,
          key: "services",
          description: this.$t("ogchat.services"),
        },
        {
          label: this.contractStatusLabel,
          key: "status",
          description: this.$t("ogchat.contract_status"),
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/main.scss";

.filter-option {
  padding: 24px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 0;
  max-width: 100%;
  gap: 16px;

  &-description {
    @include font-body-semi;
    color: $color-grey-900;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $color-grey-200;
  }

  &-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    flex: 1;
    min-width: 0;
  }

  &-action-label {
    @include font-body-reg;
    color: $color-grey-500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-action-icon {
    min-width: 22px;
  }
}

.handled-toggle::before {
  background: $color-brand-600 !important;
}

:deep(.search-bar__input) {
  border: none !important;
  height: 40px;
}

:deep(.search-bar) {
  align-items: center;
}

.search-wrapper {
  padding: 16px 16px 8px 16px;
}
</style>
