<template>
  <div class="conversation-list-empty">
    <img :src="noEmail" alt="person looking into a mailbox" class="no-email-image" />
    <h2 class="header">{{ $t('ogchat.no_messages') }}</h2>
    <p class="text">{{ $t('ogchat.no_messages_subtext') }}</p>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import noEmail from '@/assets/images/no-email.svg';

export default defineComponent({
  name: "ConversationListEmpty",
  data() {
    return {
      noEmail
    }
  },
  components: {},
  props: {},
  computed: {}
})
</script>

<style scoped lang="scss">
@import './src/main.scss';

.no-email-image {
  width: 50vw;
}

.conversation-list-empty {
  padding: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.header {
  @include font-h5;
  color: $color-grey-900;
}

.text {
  @include font-body-reg;
  color: $color-grey-500;
}
</style>
