<template>
  <div
    class="status-tag"
    :class="{ ...classBinding }"
  >
    <div class="status-tag-inner">
      <Icon
        v-if="iconLeft"
        :name="iconLeft"
        :size="14"
        style="margin-right: 5px"
      />
      <slot />
      <Icon
        v-if="iconRight"
        :name="iconRight"
        :size="14"
        style="margin-left: 5px"
      />
    </div>
  </div>
</template>

<script>
import Icon from './Icon.vue';

export default {
  components: {
    Icon,
  },
  props: {
    small: {
      type: Boolean,
    },
    iconLeft: {
      type: String,
      default: null,
    },
    iconRight: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: 'grey',
      validator: (val) => ['grey', 'blue', 'primary', 'red', 'green', 'yellow', 'brand'].includes(val),
    },
  },
  computed: {
    colorClass() {
      return `status-tag--${this.color}`;
    },
    classBinding() {
      return {
        'status-tag-small': this.small,
        [this.colorClass]: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/main";

.status-tag {
  @include font-caption-med;
  color: $color-grey-600;
  padding: 0 6px;
  height: 22px;
  border-radius: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $color-grey-50;
  border: 1px solid $color-grey-100;
  overflow: hidden;

  &-small {
    @include font-caption-reg;
    color: $color-grey-600;
    height: 20px;
    padding: 0 4px;
    border-radius: 6px;
  }

  &--blue,
  &--primary {
    color: $color-semantic-info-600;
    background-color: $color-semantic-info-50;
    border: 1px solid $color-semantic-info-100;
  }

  &--green {
    color: $color-semantic-positive-600;
    background-color: $color-semantic-positive-50;
    border: 1px solid $color-semantic-positive-100;
  }

  &--yellow {
    color: $color-semantic-notice-600;
    background-color: $color-semantic-notice-50;
    border: 1px solid $color-semantic-notice-100;
  }

  &--red {
    color: $color-semantic-negative-600;
    background-color: $color-semantic-negative-50;
    border: 1px solid $color-semantic-negative-100;
  }

  &--brand {
    color: $color-brand-600;
    background-color: $color-brand-50;
    border: 1px solid $color-brand-100;
  }
}

.status-tag-inner {
  @include overflow-ellipsis;
}
</style>
