<template>
  <div class="wrapper">
    <div
      v-for="item in sortedOptions"
      :key="item.value"
      class="item"
      :class="{ 'item--selected': isSelected(item.value) }"
      @click="toggleSelection(item.value)"
    >
      <label>{{ item.label }}</label>
      <Icon
        v-if="isSelected(item.value)"
        class="item-icon"
        name="close"
        :size="14"
      />
    </div>
  </div>
</template>

<script>
import Icon from "@officeguru/components-vue3/src/components/Icon.vue";
export default {
  components: {
    Icon,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    selection: {
      type: [Array, String, null],
      required: true,
    },
  },
  computed: {
    sortedOptions() {
      return [...this.options].sort((a, b) => a.label.localeCompare(b.label));
    },
    singleSelection() {
      return !Array.isArray(this.selection);
    },
  },
  methods: {
    toggleSelection(value) {
      if (this.isSelected(value)) {
        this.$emit(
          "update",
          this.singleSelection
            ? null
            : this.selection.filter((i) => i !== value)
        );
      } else {
        this.$emit(
          "update",
          this.singleSelection ? value : [...this.selection, value]
        );
      }
    },
    isSelected(value) {
      if (this.singleSelection) {
        return this.selection === value;
      }
      return this.selection.includes(value);
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/main";
.wrapper {
  padding: 24px 16px 0 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

label {
  @include font-body-med;
  color: $color-grey-900;
  pointer-events: none;
}

.item {
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: border 0.2s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-icon {
    color: $color-grey-900;
  }

  &--selected {
    border-color: $color-grey-700;

    label {
      @include font-body-med;
      color: $color-grey-900;
    }
  }
}
</style>
