<template>
  <div>
    <div :id="modalId" class="conversation-list-modal" expand="block" :class="{ 'active': isActive }">
      <Icon name="funnel" :size="20"/>
      <div v-if="isActive && activeFilterCount > 0" class="filter-count">
        <span style="margin-top: 2px">{{ activeFilterCount }}</span>
      </div>
    </div>
    <ion-modal ref="modal" :trigger="modalId" cssClass="conversation-list-filters-modal" @willDismiss="reset">
      <div class="filter-wrapper">
        <div class="filter-header">
          <Icon :name="currentFilterSelector ? 'arrow-left' : 'close'" :size="22" @click="cancel" />
          <span class="filter-header-text" :class="currentFilterSelector ? 'slide-left' : 'slide-right'" :key="currentFilterSelectorLabel">{{ currentFilterSelectorLabel }}</span>
          <div></div>
        </div>
        <div class="filter-content">
          <TransitionGroup name="list" tag="div">
            <ConversationListFiltersOverview
                v-if="!currentFilterSelector"
                key="overview"
                id="overview"
                :handled="handledBoolValue"
                @update-handled="handledSelectionUpdate"
                class="filter-page"
                @show-filter="currentFilterSelector = $event"
                :contact-label="selectedLabels.contacts"
                :customer-label="selectedLabels.customers"
                :service-label="selectedLabels.services"
            />
            <ConversationListFiltersSelector v-if="currentFilterSelector === 'customers'" key="customers" class="filter-page" :options="customersOptions" :selection="customersSelectionLocal" @update="customersSelectionLocal = $event" />
            <ConversationListFiltersSelector v-if="currentFilterSelector === 'services'" key="services" class="filter-page" :options="servicesOptions" :selection="servicesSelectionLocal" @update="servicesSelectionLocal = $event" />
            <ConversationListFiltersSelector v-if="currentFilterSelector === 'contacts'" key="contacts" class="filter-page" :options="contactsOptions" :selection="contactsSelectionLocal" @update="contactsSelectionLocal = $event"/>
          </TransitionGroup>

        </div>
        <div class="filter-footer">
          <button v-if="currentFilterHasSelection" class="clear-button" @click="clear">{{ currentFilterSelector ? $t('ogchat.clear_filter') : $t('ogchat.clear_all_filters') }}</button>
          <button class="save-button" @click="apply">{{ $t('ogchat.save') }}</button>
        </div>
      </div>

    </ion-modal>
  </div>
</template>

<script>
import api from '@/store/plugins/api.js';
import Icon from '@officeguru/components-vue3/src/components/Icon.vue';
import ConversationListFiltersOverview from '@/views/conversation-list/components/conversation-list-filters-overview.vue';
import ConversationListFiltersSelector
  from '@/views/conversation-list/components/conversation-list-filters-selector.vue';
import {
  IonModal,
} from '@ionic/vue';
import { randomId } from '@officeguru/webapp-shared/src/helpers/random-id';

export default {
  name: 'ConversationListFilters',
  components: {
    Icon,
    IonModal,
    ConversationListFiltersOverview,
    ConversationListFiltersSelector,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      contactsOptions: [],
      contactsSelectionLocal: [], // will contain user ids
      servicesOptions: [],
      servicesSelectionLocal: [], // will contain service ids
      customersOptions: [],
      customersSelectionLocal: [], // will contain customer ids
      handledSelectionLocal: null,
      currentFilterSelector: null,
      modalId: randomId()
    }
  },
  computed: {
    currentFilterHasSelection() {
      if (this.currentFilterSelector === 'contacts') {
        return this.contactsSelectionLocal.length > 0;
      }
      if (this.currentFilterSelector === 'services') {
        return this.servicesSelectionLocal.length > 0;
      }
      if (this.currentFilterSelector === 'customers') {
        return this.customersSelectionLocal.length > 0;
      }
      return this.activeFilterCount > 0;
    },
    currentFilterSelectorLabel() {
      let label = '';
      switch (this.currentFilterSelector) {
        case 'contacts':
          label = this.$t('ogchat.filter_contacts');
          break;
        case 'customers':
          label = this.$t('ogchat.filter_customers');
          break;
        case 'services':
          label = this.$t('ogchat.filter_services');
          break;
        default:
          label = this.$t('ogchat.filter_messages');
          break;
      }
      return label;
    },
    savedSelections() {
      return {
        contacts: this.values.contactPersons.contactPersons,
        handled: this.values.isHandled.isHandled,
        services: this.values.services.services,
        customers: this.values.customers.customers,
      }
    },
    handledBoolValue() {
      return this.handledSelectionLocal === true;
    },
    activeFilterCount() {
      let count = 0;
      if (this.handledSelectionLocal !== null) {
        count++;
      }
      if (this.contactsSelectionLocal.length > 0) {
        count++;
      }
      if (this.servicesSelectionLocal.length > 0) {
        count++;
      }
      if (this.customersSelectionLocal.length > 0) {
        count++;
      }
      return count;
    },
    selectedLabels() {
      return {
        customers: this.getSelectedLabels(this.customersOptions, this.customersSelectionLocal),
        contacts: this.getSelectedLabels(this.contactsOptions, this.contactsSelectionLocal),
        services: this.getSelectedLabels(this.servicesOptions, this.servicesSelectionLocal),
      }
    },
  },
  async created() {
    this.fetchOptions();
  },
  watch: {
    values: {
      handler() {
        this.reset();
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    async fetchOptions() {
      const { data: contactData } = await api.get('/partner/users?per_page=9999');
      this.contactsOptions = contactData.data.map((contact) => ({
        value: contact.id,
        label: contact.name,
      }));
      const { data: serviceData } = await api.get('partner/services?per_page=9999');
      this.servicesOptions = serviceData.data.map((service) =>({
        value: service.name,
        label: service.name,
      }));
      const { data: customerData } = await api.get('partner/customers?per_page=9999');
      this.customersOptions = customerData.data.map((customer) =>({
        value: customer.id,
        label: customer.name,
      }));
    },
    reset() {
      const { customers, contacts, handled, services } = this.savedSelections;
      this.contactsSelectionLocal = contacts;
      this.handledSelectionLocal = handled === false ? true : null;
      this.servicesSelectionLocal = services;
      this.customersSelectionLocal = customers;
    },
    handledSelectionUpdate(event) {
      if (typeof event === 'boolean') {
        this.handledSelectionLocal = event ? true : null;
      }
    },
    getSelectedLabels(options, selections) {
      return options.filter(item => selections.includes(item.value)).map(item => item.label).join(', ');
    },
    cancel() {
      if (this.currentFilterSelector) {
        this.currentFilterSelector = null;
      } else {
        this.reset();
        this.$refs.modal.$el.dismiss(null, 'cancel');
      }
    },
    apply() {
      this.$emit('filtersUpdated', {
        contacts: this.contactsSelectionLocal,
        customers: this.customersSelectionLocal,
        services: this.servicesSelectionLocal,
        handled: this.handledSelectionLocal ? false : null,
      });
      this.cancel();
    },
    clear() {
      switch (this.currentFilterSelector) {
        case 'contacts':
          this.$emit('filtersUpdated', { ...this.savedSelections, contacts: [] });
          this.currentFilterSelector = null;
          break;
        case 'customers':
          this.$emit('filtersUpdated', { ...this.savedSelections, customers: [] });
          this.currentFilterSelector = null;
          break;
        case 'services':
          this.$emit('filtersUpdated', { ...this.savedSelections, services: [] });
          this.currentFilterSelector = null;
          break;
        default:
          this.$emit('filtersCleared');
          break;
      }
    },
  },
  mounted() {
    this.$emit('interface', {
      reload: this.fetchOptions,
    })
  }
}
</script>

<style scoped lang="scss">
@import './src/main.scss';

.conversation-list-modal {
  color: $color-grey-700;
  position: relative;
}
::part(content) {
  border-radius: 16px 16px 0 0;
}

.filter-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $color-grey-200;
  padding: 16px;
  border-radius: 16px 16px 0 0;

  &-text {
    @include font-body-lg-semi;
    color: $color-grey-900;
  }
}

.filter-content {
  flex: 1;
  position: relative;
}

.filter-page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  max-height: 100%;
}

.filter-footer {
  border-top: 1px solid $color-grey-200;
  display: flex;
  gap: 8px;
  padding: 16px;
}

button {
  @include font-body-med;
  border-radius: 8px;
  padding: 8px 16px;
}

.clear-button {
  background-color: transparent;
  color: $color-grey-700;
}

.save-button {
  flex: 1;
  background-color: $color-grey-900;
  color: white;
}

.select-handled, .select-contact {
  padding: 16px;
  margin: 16px 0;
}

.active {
  color: $color-grey-900;
}

// will run below the status bar on top on iOS w/o this padding
.conversation-list-filters-modal {
  &.ios {
    padding-top: 50px;
  }
}

// transitions
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}

.list-enter-from {
  transform: translateX(100%);
}

.list-enter-from:is(#overview) {
  transform: translateX(-100%);
}

.list-leave-to {
  transform: translateX(100%);
}

.list-leave-to:is(#overview) {
  transform: translateX(-100%);
}

@keyframes slideLeft {
  from {
    opacity: 0;
    transform: translateX(40vw);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideRight {
  from {
    opacity: 0;
    transform: translateX(-40vw);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-left {
  animation: slideLeft .3s forwards ease;
}

.slide-right {
  animation: slideRight .3s forwards ease;
}

.filter-count {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $color-grey-900;
  border: 2px solid white;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
}
</style>