<template>
  <div class="list-card-wrapper" :class="rootClasses" data-cy="view-conversation-list-conversation">
    <div class="left">
      <ServiceIcon
          v-if="!conversation.is_support"
          :service-plain-text-id="service.plain_text_id"
          :service-name="service.name"
          :is-verified="service.is_verified || false"
          style="margin-right: 12px"
      />
      <ConversationsOgIcon
          v-else
      />
    </div>
    <div class="right">
      <div class="top">
        <div class="name-and-pin">
          <span class="participant">{{ conversationPartner }} <Icon
              v-if="conversation.is_support"
              class="pin-icon"
              name="pin"
              :size="11"
          /></span>
        </div>
        <InfoTag v-if="showUnhandledTag" color="yellow" x-small>{{ $t('ogchat.unhandled') }}</InfoTag>
      </div>
      <div class="bottom">
        <div class="bottom-text-container">
          <div class="service-name" v-if="serviceName">{{ serviceName }}</div>
          <div class="latest-message" :class="{ 'extended-message': !serviceName }" v-html="latestMessage" />
        </div>
        <div class="time">
          {{ latestMessageCreatedAt }}
        </div>
<!--        <span class="latest-message-unread-indicator" v-if="!latestConversationRead"></span>-->
      </div>
    </div>
    <div v-if="!latestConversationRead" class="latest-message-unread-indicator"></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import striptags from 'striptags';
import {participantName, taskName} from '@officeguru/webapp-shared/src/helpers/conversation.js';
import ServiceIcon from '@officeguru/components-vue3/src/components/service-icon.vue';
import Icon from '@officeguru/components-vue3/src/components/Icon.vue';
import ConversationsOgIcon from '@officeguru/components-vue3/src/views/conversations/components/conversations-og-icon.vue';
import { isToday, isYesterday, isThisYear, format } from 'date-fns';
import InfoTag from "@officeguru/components-vue3/src/components/InfoTag.vue";
export default defineComponent({
  name: "ConversationListCard",
  components: {
    ServiceIcon,
    ConversationsOgIcon,
    Icon,
    InfoTag,
  },
  props: {
    conversation: {
      type: Object,
      required: true,
    }
  },
  computed: {
    showUnhandledTag() {
      return !this.isHandled && this.isPartner && this.conversationHandlingEnabled;
    },
    conversationHandlingEnabled() {
      return this.$store.getters['split/canAccess'](
          'ogchat-handle-conversations'
      );
    },
    isPartner() {
      return this.me.type === 'partner';
    },
    isHandled() {
      return !!this.conversation.handled?.user;
    },
    rootClasses() {
      return {
        'list-card-wrapper--unread': !this.latestConversationRead,
      }
    },
    me() {
      return this.$store.getters['users/me'];
    },
    serviceName() {
      return taskName(this.conversation, this.$t);
    },
    service() {
      return this.conversation?.task?.service || {};
    },
    conversationPartner() {
      return participantName(this.conversation, this.me.type, this.$t);
    },
    latestMessage() {
      let message;
      let files;
      try {
        const content = JSON.parse(this.conversation?.latest_log.content)
        message = content && content.message;
        files = content && content.files;
        // eslint-disable-next-line no-empty
      } catch(e) {}

      const plainMessage = striptags(typeof message === 'string' ? message : '', [], ' ').trim();

      if (!plainMessage && Array.isArray(files) && files.length > 0) {
          return this.$t('shared.conversations.file_added');
      }

      return plainMessage;
    },
    latestConversationRead() {
      if (!this.conversation.latest_log) return true;

      return !!this.conversation.latest_log.read;
    },
    latestMessageCreatedAt() {
      const givenDateString = this.conversation.latest_log && this.conversation.latest_log.created_at;

      if (!givenDateString) return null;

      const givenDate = new Date(givenDateString);

      if (isToday(givenDate)) {
        return format(givenDate, 'HH:MM');
      }

      if (isYesterday(givenDate)) {
        return `${this.$t('shared.conversations.yesterday')}, ${format(givenDate, 'HH:MM')}`;
      }

      // TODO: Properly localize when having multiple languages
      return isThisYear(givenDate) ? format(givenDate,'dd. LLL') : format(givenDate,'PP');
    },
  }
})
</script>

<style scoped lang="scss">
@import './src/main.scss';

.list-card-wrapper {
  display: flex;
  padding: 16px;
  align-items: center;

  &:not(:first-child) {
    border-top: 1px solid $color-grey-100;
  }
}

.top {
  display: flex;
  flex: 1 0 100%;
  height: 20px;
  align-items: center;
}

.bottom {
  display: flex;
  align-items: flex-end;
  flex: 1 1 100%;
  height: 40px;
}

.left {
  display: flex;
  flex: 1 0 auto;
  align-self: flex-start;
  margin-top: 4px;
}

.right {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  min-width: 0;
}

.name {
  @include font-body-med;
  @include overflow-ellipsis;
  color: $color-grey-900;
  margin-bottom: -4px;

  .list-card-wrapper--unread & {
    @include font-body-semi;
    color: $color-grey-900;
  }
}

.participant {
  @include font-body-semi;
  @include overflow-ellipsis;
  color: $color-grey-900;
  margin-right: 12px;
}

.name-and-pin {
  display: flex;
  flex: 1;
  height: 20px;
  overflow: hidden;
}

.latest-message {
  @include font-body-reg;
  @include overflow-ellipsis;
  flex: 1;
  color: $color-grey-500;
  padding: 0;
  margin: 0;
  line-height: 20px;

  .list-card-wrapper--unread & {
    color: $color-grey-700;
  }
}

.latest-message-unread-indicator {
  flex: 0 0 auto;
  height: 10px;
  width: 10px;
  background-color: $color-grey-900;
  border-radius: 50%;
  margin-left: 12px;
}

.pin-icon {
  color: $color-grey-900;
  margin-left: 4px;
  min-width: 11px;
  margin-bottom: -1px;
}

.service-name {
  @include font-body-reg;
  @include overflow-ellipsis;
  color: $color-grey-900;
  line-height: 20px;
  height: 20px;
}

.bottom-text-container {
  max-height: 40px;
  height: 40px;
  overflow:hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.extended-message {
  -webkit-line-clamp: 2;
  word-break: break-word;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
}

.time {
  @include font-caption-reg;
  color: $color-grey-500;
  margin-left: 12px;
  line-height: 20px;
  height: 20px;
}
</style>
