<template>
  <div
    class="message-footer"
    :class="{
      'message-footer--disabled': !canChat || disabled,
      'message-footer-bulk': bulk,
    }"
  >
    <button class="action-popover-trigger" @click="openPopover">
      <Icon name="plus" class="icon" />
    </button>
    <OGPopover
      class="action-popover"
      :showing="popoverShowing"
      @close="closePopover"
    >
      <div class="action-popover__actions">
        <div
          v-if="canChat && !disabled"
          class="action-popover__action"
          @click="takePhoto"
        >
          <Icon name="camera" :size="24" class="icon" /><span>{{
            $t("ogchat.take_photo")
          }}</span>
        </div>
        <div
          v-if="canChat && !disabled"
          class="action-popover__action"
          @click="pickPhotos"
        >
          <Icon name="gallery" :size="24" class="icon" /><span>{{
            $t("ogchat.select_from_gallery")
          }}</span>
        </div>
        <div
          v-if="showUnhandleButton"
          class="action-popover__action"
          @click="unhandle"
        >
          <Icon name="times" :size="24" class="icon" /><span>{{
            $t("ogchat.mark_unhandled")
          }}</span>
        </div>
        <div
          v-else-if="showHandleButton"
          class="action-popover__action"
          @click="handle"
        >
          <Icon name="checkmark_bubble_light" :size="24" class="icon" /><span>{{
            $t("ogchat.mark_handled")
          }}</span>
        </div>
      </div>
    </OGPopover>

    <div class="compose-message">
      <div class="compose-message__images" v-if="hasImages">
        <div
          v-for="(img, index) in images"
          :key="img.webPath"
          style="position: relative"
        >
          <button
            class="compose-message__delete-image"
            @click="deletePhoto(index)"
          >
            <Icon name="cross" />
          </button>
          <img
            v-if="img.remotePath"
            class="compose-message__image"
            :class="{ 'compose-message__image--uploaded': img.remotePath }"
            :src="img.webPath"
            alt=""
          />
          <div v-else class="compose-message__image_placeholder">
            <Icon
              name="loading"
              class="compose-message__image_loader rotate-indefinitely"
            />
          </div>
        </div>
      </div>

      <div
        :contenteditable="canChat"
        class="compose-message__text"
        :data-placeholder="messageTextPlaceholder"
        @input="parseMessageFromEvent"
        ref="message"
      />

      <div
        class="send-message-bubble"
        :class="{ 'send-message-bubble--opaque': isOffline }"
        @click="send"
        v-if="showSendIcon"
      >
        <Icon
          v-if="!sending"
          name="paper-plane"
          class="send-icon"
          :class="{ 'send-flight': messageSent }"
        />
        <Icon v-else name="loading" class="send-icon rotate-indefinitely" />
      </div>
    </div>

    <OgTooltip
      :message="$t('ogchat.no_internet_connection')"
      @interface="noConnectionTooltipInterface = $event"
      ref="noConnectionTooltip"
      data-cy="no-connection-tooltip"
    />

    <OgTooltip
      :message="$t('ogchat.could_not_send')"
      @interface="sendingFailedTooltipInterface = $event"
      ref="sendingFailedTooltip"
      data-cy="sending-failed-tooltip"
    />

    <OgTooltip
      :message="$t('ogchat.handled_status_update_failed')"
      @interface="handlingFailedTooltipInterface = $event"
      ref="handlingFailedTooltip"
      data-cy="handling-failed-tooltip"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Capacitor } from "@capacitor/core";
import { Camera, CameraResultType } from "@capacitor/camera";
import OGPopover from "@/components/OGPopover.vue";
import api from "@/store/plugins/api";
import Icon from "@officeguru/components-vue3/src/components/Icon.vue";
import OgTooltip from "@/components/OgTooltip.vue";
import { bugsnag } from "@/libs/bugsnag";
import { Network } from "@capacitor/network";

export default defineComponent({
  name: "ConversationMessageCompose",
  components: {
    OgTooltip,
    Icon,
    OGPopover,
  },
  emits: ["send", "interface"],
  props: {
    conversation: {
      type: Object,
    },
    canChat: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    bulk: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      images: [],
      message: "",
      messageSent: false,
      sending: false,
      noConnectionTooltipInterface: () => {},
      sendingFailedTooltipInterface: () => {},
      handlingFailedTooltipInterface: () => {},
      popoverShowing: false,
    };
  },
  watch: {
    showSendIcon(sendIconShown) {
      if (sendIconShown) return;

      this.noConnectionTooltipInterface.hide();
      this.sendingFailedTooltipInterface.hide();
    },
    isOffline(value) {
      // if we're online again, hide the no connection tooltip if currently active
      if (!value) this.noConnectionTooltipInterface.hide();
    },
  },
  computed: {
    isNative() {
      return Capacitor.isNativePlatform();
    },
    showHandleButton() {
      return (
        !this.bulk &&
        !this.isHandled &&
        this.isPartner &&
        this.conversationHandlingEnabled
      );
    },
    showUnhandleButton() {
      return (
        !this.bulk &&
        this.isHandled &&
        this.isPartner &&
        this.conversationHandlingEnabled
      );
    },
    conversationHandlingEnabled() {
      return this.$store.getters["split/canAccess"](
        "ogchat-handle-conversations"
      );
    },
    isPartner() {
      return this.me.type === "partner";
    },
    me() {
      return this.$store.getters["users/me"];
    },
    isHandled() {
      if (this.bulk) return true;
      return !!this.conversation.handled?.user;
    },
    hasImages() {
      return Array.isArray(this.images) && this.images.length;
    },
    showSendIcon() {
      if (this.bulk) return false;
      // If there are images, they need to be uploaded successfully
      if (this.images.length > 0 && !this.images.every((i) => i.remotePath)) {
        return false;
      }

      // Having either a message or images or both :)
      return !!this.message || !!this.images.length;
    },
    messageTextPlaceholder() {
      if (!this.canChat)
        return this.$t("shared.conversations.you_can_no_longer_chat");
      if (!this.hasImages) return this.$t("ogchat.write_message");
      return this.$t("ogchat.write_message_or_send");
    },
    isOffline() {
      return !this.$store.getters["settings/isOnline"];
    },
  },
  mounted() {
    this.$emit("interface", {
      send: this.send,
      clear: this.clear,
    });
  },
  methods: {
    openPopover() {
      this.popoverShowing = true;
    },
    closePopover() {
      this.popoverShowing = false;
    },
    handle() {
      this.$store
        .dispatch("conversations/handle", {
          conversationId: this.conversation.id,
        })
        .catch((e) => {
          this.handlingFailed(e, "handle");
        });
    },
    unhandle() {
      this.$store
        .dispatch("conversations/unhandle", {
          conversationId: this.conversation.id,
        })
        .catch((e) => {
          this.handlingFailed(e, "unhandle");
        });
    },
    handlingFailed(e, handleType) {
      bugsnag.notify(e, (event) => {
        event.context = `OGChat user tried to ${handleType} a conversation, but backend responded with non success.`;
        event.addMetadata("additional", {
          isSupport: this.conversation.is_support,
          id: this.conversation.id,
          error: e,
          user: this.me,
          network_status: Network.getStatus(),
        });
      });

      this.handlingFailedTooltipInterface.toggle();
    },
    async takePhoto() {
      if (!this.isNative) {
        return alert("Disabled because causes trouble in web");
      }

      const photo = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Uri,
      });

      this.uploadFile(photo);

      this.images = [...this.images, photo];
    },
    async pickPhotos() {
      if (!this.isNative) {
        return alert("Disabled because causes trouble in web");
      }

      const { photos } = await Camera.pickImages({
        quality: 90,
      });

      const photosWithStatus = photos.map((p) => {
        this.uploadFile(p);
        return p;
      });

      this.images = [...this.images, ...photosWithStatus];
    },
    deletePhoto(index) {
      const images = [...this.images];
      images.splice(index, 1);
      this.images = images;
    },
    send() {
      this.sendingFailedTooltipInterface.hide();
      if (!this.canChat || this.disabled) return;
      if (this.isOffline) {
        this.noConnectionTooltipInterface.toggle();
        return;
      }

      this.sending = true;

      this.$emit("send", {
        payload: {
          text: this.transformTextContent(this.message),
          files: this.images.map((i) => i.remotePath),
        },
        sentStatusCallback: this.sentStatusCallback.bind(this),
      });
    },
    clear() {
      this.images = [];
      this.message = "";
      this.$refs.message.innerText = "";
    },
    sentStatusCallback(error = false) {
      this.sending = false;

      if (error) {
        // if we failed to send, hide the connection tooltip if open and show the sending failed tooltip
        this.noConnectionTooltipInterface.hide();
        this.sendingFailedTooltipInterface.show();
        return;
      }

      this.messageSent = true;

      this.clear();

      setTimeout(() => {
        this.messageSent = false;
      }, 500);
    },
    transformTextContent() {
      if (typeof this.message !== "string") return this.message;

      const allParagraphs = this.message
        .replace(/<div>/gi, "<p>")
        .replace(/<\\*\/div>/gi, "</p>");

      if (allParagraphs.indexOf("<p>") === -1) return allParagraphs;

      const parts = allParagraphs.split("<p>");
      const first = "<p>" + parts.shift() + "</p><p>";

      return `${first}${parts.join("")}`;
    },
    async uploadFile({ path }) {
      const src = Capacitor.convertFileSrc(path);
      const resp = await fetch(src);
      const blob = await resp.blob();

      const params = new FormData();
      params.append("file", blob, path.split("/").at(-1));

      const { data } = await api.request({
        url: "medias",
        method: "post",
        data: params,
      });

      const file = this.images.find((f) => f.path === path);
      if (file) {
        file.remotePath = data.data.sizes.original
          .split("/")
          .slice(-2)
          .join("/");
      }
    },
    parseMessageFromEvent($event) {
      let html = $event.target.innerHTML;
      // iOS yields a '<br>' when the field is emptied, convert that to an empty string
      // so our empty logic still behaves correct and also set the content of the field
      // to an empty string so our css empty selector works
      if (html === "<br>") {
        $event.target.textContent = "";
      }
      this.message = html === "<br>" ? "" : html;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/main.scss";

.action-popover-trigger {
  display: flex;
  align-self: flex-end;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
  margin-right: 11px;
  background: $color-grey-100;
  border-radius: 8px;
  padding: 8px;
  width: 40px;
  height: 40px;

  .icon {
    color: $color-grey-700;
  }
}

.action-popover {
  &__actions {
    display: flex;
    flex-direction: column;
    padding: 16px;
    background: white;
    border-radius: 12px;
    border: 1px solid $color-grey-100;
  }

  &__action {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    span {
      @include font-body-med;
      color: $color-grey-900;
      white-space: nowrap;
      display: block;
      margin-left: 12px;
      margin-bottom: -3px;
    }

    .icon {
      color: $color-grey-900;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.message-footer {
  display: flex;
  padding: 12px 16px;
  border-radius: 12px 12px 0 0;
  border-top: 1px solid $color-grey-200;
  background-color: white;

  &--disabled {
    * {
      opacity: 0.7;
    }
  }
}

.compose-message {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid $color-grey-200;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  word-break: break-word;

  &__images {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;
  }

  &__image {
    margin-right: 8px;
    border-radius: 4px;
    object-fit: cover;
    min-width: 10vw;
    max-width: 10vw;
    max-height: 10vw;
    min-height: 10vw;
    opacity: 1;
  }

  &__image_placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    border-radius: 4px;
    object-fit: cover;
    min-width: 10vw;
    max-width: 10vw;
    max-height: 10vw;
    min-height: 10vw;
    background: $color-grey-50;
  }

  &__image_loader {
    color: $color-grey-900;
    width: 50%;
    height: 50%;
  }

  &__text {
    @include font-body-reg;
    max-height: 25vh;
    overflow-x: auto;
    padding-right: 28px;
    margin-bottom: -1px;

    &:focus-visible,
    &:focus,
    &:focus-within {
      outline: none;
      border: none;
      box-shadow: none;
    }

    &[contenteditable] {
      color: $color-grey-900;
    }

    &[data-placeholder]:empty:before {
      content: attr(data-placeholder);
      color: $color-grey-500;
    }
  }

  &__delete-image {
    position: absolute;
    top: -6px;
    right: 3px;
    width: 16px;
    height: 16px;
    padding: 0;
    margin: 0;
    background: $color-grey-200;
    border: 2px solid white;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 70%;
      height: 70%;
      margin-left: 1px;
    }
  }
}

.send-message-bubble {
  position: absolute;
  right: -4px;
  bottom: 3px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &--opaque {
    opacity: 0.3;
  }
}

.no-internet-connection-tooltip {
  position: absolute;
  right: 0;
  bottom: 43px;
  background: $color-grey-900;
  border-radius: 8px;
  padding: 8px 12px;
  opacity: 0;
  transition: opacity 0.5s;

  &--open {
    opacity: 1;
  }
}

.no-internet-connection-tooltip {
  position: absolute;
  right: 16px;
  bottom: 56px;
  background: $color-grey-900;
  border-radius: 8px;
  padding: 8px 12px;
}

.no-internet-connection-tooltip-pointer {
  position: absolute;
  bottom: -6px;
  right: 5px;
}

.no-internet-connection-tooltip-text {
  @include font-body-reg;
  color: white;
}

.send-icon {
  color: $color-grey-900;
  height: 22px;
  width: 22px;
  animation: sendIcon 0.2s linear forwards;
}

.send-flight {
  animation: sendFlight 0.2s linear forwards !important;
}

.rotate-indefinitely {
  animation: rotate 1.5s infinite linear;
}

.message-footer-bulk {
  flex-direction: column-reverse;
  align-items: flex-end;
  height: 100%;
  gap: 8px;

  .compose-message {
    flex: 1;
  }

  .action-popover-trigger {
    margin-right: auto;
  }

  .compose-message__text {
    @include font-body-reg;
    color: $color-grey-900;
    max-height: none;
    height: 100%;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes sendIcon {
  0% {
    margin-top: 100px;
    margin-left: -100px;
  }

  80% {
    margin-top: -10px;
    margin-left: 10px;
  }

  100% {
    margin-top: 0;
    margin-left: 0;
  }
}

@keyframes sendFlight {
  0% {
    margin-top: 0;
    margin-left: 0;
  }

  20% {
    margin-top: 10px;
    margin-left: -10px;
  }

  100% {
    margin-top: -100px;
    margin-left: 100px;
  }
}
</style>
